<template>
  <component :is='contentComponentBySessionType'
    :show-session-content-select='false'
    :show-session-speakers='false'
    :show-session-title-top='true'
    :show-session-details-full='false'
    :show-session-details-short='false'
    :show-session-topics='false'
    :show-session-questions-box='true' />
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import LiveSession  from '@/components/sessions/v2/LiveSession.vue'
import VodSession   from '@/components/sessions/VodSession.vue'

export default {
  name: 'Session',
  components: {
    LiveSession,
    VodSession,
  },
  watch: {
    $route: {
      handler: function (to, from) {
        if (to !== from) {
          this.getSingleSessionV2(this.$route.query.session_id).then(() => {
            if (this.selectedSessionConfigBlockViewingBeforeStartTime && new Date() < new Date(this.selectedSession.scheduledStartTime)) {
              this.createUserAction({
                userActionName: 'block_open_session_page_before_start_time',
                userActionData: {
                  event_id: this.showingEventId,
                  session_id: this.sessionID,
                  session_type: this.contentComponentBySessionType
                }
              })
              this.$router.push({ name: 'Programs' })
              this.$alert(`${this.selectedSessionConfigBlockViewingBeforeStartTimeMessage}`, {
                confirmButtonText: this.selectedSessionConfigBlockViewingBeforeStartTimeButtonText,
                type: 'info'
              })
            } else {
              this.createUserAction({
                userActionName: 'open_session_page',
                userActionData: {
                  event_id: this.showingEventId,
                  session_id: this.sessionID,
                  session_type: this.contentComponentBySessionType
                }
              })
            }
          })
          this.getSessionNotices(this.$route.query.session_id)
          this.unsubscribeFromNotices()
          this.subscribeToNotices(this.$route.query.session_id)
          this.unsubscribeFromGoto()
          this.subscribeToGoto(this.$route.query.session_id)
        }
      },
      immediate: true
    },
    'contentComponentBySessionType': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal && newVal === 'live-session') {
          this.checkCurrentSessionId()
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState('sessions', [
      'selectedSession'
    ]),
    ...mapGetters('sessions', [
      'selectedSessionType',
      'selectedSessionConfigBlockViewingBeforeStartTime',
      'selectedSessionConfigBlockViewingBeforeStartTimeMessage',
      'selectedSessionConfigBlockViewingBeforeStartTimeButtonText',
    ]),
    ...mapGetters('events', [
      'showingEventId'
    ]),
    sessionID () {
      return parseInt(this.$route.query.session_id)
    },
    contentComponentBySessionType () {
      if (this.selectedSessionType === 'vod') {
        return 'vod-session'
      } else if (this.selectedSessionType === 'live_stream') {
        return 'live-session'
      } else {
        return ''
      }
    },
  },
  methods: {
    ...mapActions('sessions', [
      'getCurrentSessionId',
      'getSingleSessionV2',
      'subscribeToGoto',
      'unsubscribeFromGoto',
    ]),
    ...mapActions('sessionNotices', [
      'getSessionNotices',
      'subscribeToNotices',
      'unsubscribeFromNotices',
    ]),
    ...mapActions('userActions', [
      'createUserAction'
    ]),
    checkCurrentSessionId () {
      this.currentSessionIdChecker = setInterval(() => {
        if (!this.$route.query.session_id) {
          clearInterval(this.currentSessionIdChecker)
        } else {
          this.getCurrentSessionId(this.$route.query.session_id).then((currentSessionId) => {
            if (currentSessionId) {
              // 다음 세션 있을 때
              if (parseInt(this.$route.query.session_id) !== currentSessionId) {
                // 지금 page query랑 다를 때
                this.$alert('This session has ended. Navigating to current session.', {
                  type: 'info',
                  confirmButtonText: 'Go to Current'
                }).then(() => {
                  this.$router.replace({name: 'Session', query: {session_id: currentSessionId, autoplay: true}})
                })
                setTimeout(() => {
                  this.$router.replace({name: 'Session', query: {session_id: currentSessionId, autoplay: true}})
                  location.reload(true)
                }, 5000)
              }
            } else {
              // 다음 세션 없을 때
              this.$alert('There are no sessions in progress. Navigating to the programs page.', {
                type: 'info',
                confirmButtonText: 'Go to Programs'
              }).then(() => {
                this.$router.replace({name: 'Programs'})
              })
              setTimeout(() => {
                this.$router.replace({name: 'Programs'})
                location.reload(true)
              }, 5000)
            }
          })
        }
      }, 120000) // call api per 2 minutes
    }
  },
}
</script>
